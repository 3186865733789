@import "index.css";

.App {
    position: relative;
    width: calc(100% - 55px);
    overflow: hidden;
}

.App:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    z-index: -1;
}

.main_container {
}

.parallax_box {
    perspective: 1px;
    min-height: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.parallax_box::-webkit-scrollbar {
    display: none;
}

.parallax_box::-webkit-scrollbar {
    display: none;
}

.parallax_box:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    z-index: -1;
    opacity: 0.2;
    box-sizing: border-box;
}


.top-page:before {
    background-image: url("asset/woodorweb.webp");
}

.oeuvres-page:before {
    background-image: url("asset/woodblueweb.webp");
}

.tarot-page:before {
    background-image: url("asset/woodppl.webp");
    opacity: 0.1;
}

.bio-page:before {
    background-image: url("asset/woodyweb.webp");
}

.contact-page:before {
    background-image: url("asset/woodgrweb.webp");
}

.page_content {
    padding: 40px 12px;
}

.page_content h2 {
    margin: 2rem 0;
}

.subpage {
    padding: 22px 12px;
}

.subpage h2 {
    margin: 180px 3% 100px;
    padding: 0 10px;
    font-size: 3rem;
    border-left: 3px solid #1a1a1a;
    color: #575757;
}

.style_jp.subpage h2 {
    font-size: 2rem;
    border-left: 3px solid #1a1a1a;
    color: #575757;
    font-family: 'Zen Antique', serif !important;
}

.style_jp.subpage p {
    font-family: 'Zen Antique', serif !important;
}

.page404 h2 {
    margin: 100px 0 100px 5%;
}


@media screen and (min-width: 381px)  and (max-width: 768px) {

}

@media screen and (min-width: 769px) {
    .App {

    }

    .home, .subpage {
        margin: 0 auto;
        max-width: 720px;
    }

}


@media screen and (min-width: 769px) {


}