.sec_galerie {
    position: relative;
    top: 0;
    left: 0;
    transition: 0.2s;
    opacity: 1;
}

.sec_galerie .sec_galerie_container {
    background-color: rgba(254, 254, 254, 0.6);
}

.sec_galerie .sec_galerie_half {
    width: 50%;
    display: inline-block;
}


.galerie_box {
    padding-bottom: 16px;
    position: relative;
    transform: translate(0) rotateY(0deg) scale(0.9);
    transition: 0.6s;
    background-color: rgba(254, 254, 254, 0.6);
}

.galerie_shadow {
    position: relative;
    overflow: hidden;
    filter: drop-shadow(12px 12px 4px rgba(31, 31, 31, 0.5));
    margin-bottom: 16px;
}

.galerie_shadow img {
    z-index: 0;
    width: 101%;
    vertical-align: top;
    clip-path: polygon(0 0, 100% 0, 100% 99%, 0 99%);
}


.galerie_shadow:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 10000;
}

.sold:after, .nonsidp:after {
    background-color: rgba(244, 245, 247, 0.4);
}


.sold:before {
    content: "SOLD";
    position: absolute;
    top: -120px;
    left: -300px;
    width: 600px;
    height: 240px;


    color: #F4F5F7;
    font-size: 2rem;
    line-height: 410px;
    text-align: center;


    background-color: #f10000;
    rotate: -45deg;
    z-index: 10001;
}


.nonsidp:before {
    content: "Non disponible";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    height: 100px;

    color: #F4F5F7;
    font-size: 2rem;
    line-height: 100px;
    text-align: center;

    background-color: rgba(65, 65, 65, 0.6);
    z-index: 10001;
}


.galerie_title {
    margin: 0 6px;
    font-size: 1.4rem;
    color: #000000;
    font-family: "Cinzel", serif;
}

.galerie_titlejp {
    margin: 0 12px;
    font-size: 2rem;
    color: #000000;
    font-family: "MS Mincho", serif;
}

.galerie_prix {
    text-align: right;
    margin: 0 12px 12px;
    font-weight: bold;
    font-size: 2rem;
    font-family: 'Monotype_Corsiva', serif;
}

.galerie_prix span {
    font-family: 'Monotype_Corsiva', serif;
    font-size: 2.6rem;
}

.galerie_size {
    text-align: right;
    margin: 0 12px;
    font-size: 1rem;
}

.galerie_type {
    text-align: right;
    margin: 0 12px;
    font-size: 0.9rem;
}

.galerie_size span {
    font-size: 1.4rem;
}

.galerie_year {
    margin: 0 12px;
}

.galerie_btn, .undisp, .nonsales {
    display: block;
    font-size: 2rem;
    width: 100%;
    text-align: center;
}

.undisp p {
    color: #f10000;
    font-size: 2.2rem;
}

.galerie_btn label {
    display: inline-block;
    padding: 4px 70px;
    color: #eeeeee;
    border-radius: 25px;
    max-width: 80%;
    background: rgb(146, 146, 146);
    background: radial-gradient(circle, rgba(146, 146, 146, 1) 0%, rgba(10, 10, 10, 1) 100%);
}


.contactWithId {
    position: absolute;
    top: 0;
    left: 200%;
    padding: 22px 12px;
    opacity: 0;
    transition: 0.2s;
}

input[id="tocontact"]:checked ~ .sec_galerie {
    left: -100%;
    height: 1500px;
    opacity: 0;
}

input[id="tocontact"]:checked ~ .contactWithId {
    left: 0;
    opacity: 1;
}

.contactWithId form {
    margin: 40px 0 0;
}

.contactWithId h2 {
    margin: 80px 0 40px;
    font-size: 1.7rem;
}

.contactWithId label {
    font-size: 1.6rem;
    font-style: italic;
    display: block;
    margin: 40px 0;
}

.hidden {
    display: none;
}

.style_jp .galerie_title,
.style_jp .galerie_btn {
    font-family: "Zen Antique", serif;
}

.style_jp .galerie_btn label,
.style_jp .undisp p,
.style_jp .nonsales p {
    max-width: 100%;
    white-space: nowrap;
    padding: 8px 50px;
    font-size: 1.4rem;
    font-family: "Zen Antique", serif;
}

@media screen and (min-width: 769px) {
    .sec_galerie {
        padding: 0 5%;
    }

    .sec_galerie div img, .contactWithId img {
        max-width: 100%;

    }

    .sec_galerie_container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

    }

    .item_wide {
        width: 100%;
    }

    .item_half {
        width: 50%;
    }

    .contactWithId {
        max-width: 780px;
    }

    input[id="tocontact"]:checked ~ .contactWithId {
        left: 50%;
        transform: translateX(-50%);
        opacity: 1;
    }
}