.master {
    padding: 100px 5%;
    min-height: 100vh;
    background: linear-gradient(
            135deg,
            rgba(255, 0, 0, 0.2) 0%,
            rgba(255, 154, 0, 0.2) 10%,
            rgba(208, 222, 33, 0.2) 20%,
            rgba(79, 220, 74, 0.2) 30%,
            rgba(63, 218, 216, 0.2) 40%,
            rgba(47, 201, 226, 0.2) 50%,
            rgba(28, 127, 238, 0.2) 60%,
            rgba(95, 21, 242, 0.2) 70%,
            rgba(186, 12, 248, 0.2) 80%,
            rgba(251, 7, 217, 0.2) 90%,
            rgba(255, 0, 0, 0.2) 100%
    );
}

.master div {
    max-width: 500px;
    margin-bottom: 32px;
}

.master h2 {
    margin-bottom: 16px;
    font-size: 2rem;
}

.master p {
    margin-bottom: 16px;
    font-size: 1.2rem;
    white-space: nowrap;
    color: #000000;
    border-bottom: #a6a6a6 1px solid;
    font-family: "Roboto", sans-serif;
}

.master input[type="file"] {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #008bff;
    border-radius: 50px;
    padding: 14px 20px;
    text-align: center;
    margin: 0 0 32px;
    box-shadow: 5px 5px #005ba8;
}

.master button {
    border: 1px solid #008bff;
    width: 100%;
    margin: 0 0 32px;
    padding: 10px 60px;
    font-family: "Roboto", sans-serif;
    border-radius: 50px;
    box-shadow: 5px 5px #005ba8;
}

.green button {
    border: 1px solid #00a411;
    box-shadow: 5px 5px #00690a;
}
