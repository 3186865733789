@import "../index.css";
/* */
a {
    text-decoration: none;
}

a:active {
    color: inherit;
    text-decoration: none;
}

input[type="checkbox"] {
    display: none;
}

.navi {
    position: relative;
    overflow-x: hidden;
}

.navi_trigger {
    position: fixed;
    top: 0;
    right: 0;
    width: 55px;
    height: 100%;
    background-color: #F4F5F7;
    display: flex;
    flex-direction: column;
    z-index: 999;
    transition: 0.1s;
}


.navi_trigger_label p {
    margin: 180px 0;
    font-family: 'Monotype_Corsiva', cursive;
    transform: rotate(-90deg);
    font-size: 2.2rem;
    white-space: nowrap;
}

.pc p {
    margin: 250px 0 0;
}

.navi_trigger_label {
    flex-grow: 1;
}

.navi_trigger_footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.navi_trigger_footer a {
    margin: 13px 0;
    text-align: center;
}

.navi_trigger_footer .langue {
    transform: rotate(-90deg);
    font-size: 1.4rem;
}

.link_insta {
    width: 40px;
}


.navi_content {
    position: fixed;
    top: 0;
    left: 100%;
    width: calc(100% - 55px);
    height: 100vh;
    box-sizing: border-box;
    padding-left: 0;
    background-color: rgba(238, 238, 238, 0.5);
    transition: 0.4s;
    z-index: 998;
    opacity: 0;
}

input[id="navi_checkbox"]:checked ~ .navi_trigger {
    background-color: #F4F5F7;
}

input[id="navi_checkbox"]:checked ~ .navi_content {
    left: 0;
    opacity: 1;
}

input[id="navi_checkbox"]:checked ~ .navi_content li {
    transform: translateX(0%);
}

.navi_content ul {
    list-style: none;
    height: 100%;
    padding: 50px 0;
    background-color: rgb(236, 236, 236);
    text-align: left;
}

.navi_content ul li {
    transform: translateX(100%);
    background-size: cover;
    background-position: center center;
    padding-left: 10%;
    border-bottom: #1a1a1a 1px solid;
}

.navi_content-xxx ul li:nth-child(1) {
    background-image: url("../asset/menu1.png");
}

.navi_content-xxx ul li:nth-child(2) {
    background-image: url("../asset/menu4.png");
}

.navi_content-xxx ul li:nth-child(3) {
    background-image: url("../asset/menu3.png");
}

.navi_content-xxx ul li:nth-child(4) {
    background-image: url("../asset/menu2.png");
}

.navi_content-xxx ul li:nth-child(5) {
    background-image: url("../asset/menu5.png");
}

.navi_content ul li a {
    display: block;
    padding: 25px 0;
    border-bottom: 1px solid #e3e3e3;
    font-size: 2.1rem;
    font-weight: bold;
    font-family: 'Monotype_Corsiva', cursive;
}

.navi_content ul li a span {
    font-size: 1.1rem;
}
.style_jp li a span {
    font-family: 'Zen Antique', serif;
    font-size: 1.4rem;

}
@media screen and (min-width: 769px) {
    input[id="navi_checkbox"]:checked ~ .navi_content li {
        transform: translateX(50%);
    }


    .navi_trigger {
        width: 85px;
    }
}

