@import "../index.css";

.top_form {
    padding: 0 0 60px;
}

.top_form input,
.top_form textarea {
    position: relative;
    color: #1a1a1a;
    width: 100%;
    padding: 6px 10px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.07) 0 1px 2px,
    rgba(0, 0, 0, 0.07) 0 2px 4px,
    rgba(0, 0, 0, 0.07) 0 4px 8px,
    rgba(0, 0, 0, 0.07) 0 8px 16px,
    rgba(0, 0, 0, 0.07) 0 16px 32px,
    rgba(0, 0, 0, 0.07) 0 32px 64px;
    border-radius: 10px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.4) 0%, rgba(232, 232, 232, 0.4) 100%);
    margin-bottom: 24px;
    font-size: 1.1rem;
    box-sizing: border-box;
}

.top_form input:before,
.top_form textarea:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: blur(10px);
}

.top_form input:active,
.top_form textarea:active,
.top_form input:focus,
.top_form textarea:focus {
    outline: none;
}

.top_form input[type="submit"] {
    background: linear-gradient(135deg, rgba(0, 255, 255, 0.7) 0%, rgba(85, 255, 223, 0.2) 50%, rgba(0, 250, 154, 0.7) 100%);
    width: 50%;
}

.top_form input[type="submit"]:disabled {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.4) 0%, rgba(232, 232, 232, 0.4) 100%);
    width: 50%;
}

.top_form input {
    line-height: 2rem;
}

.top_form textarea {
    line-height: 1.4rem;
    min-height: 200px;
    padding: 10px;
    height: 80vh;
}

input::placeholder, textarea::placeholder {
    color: #797979;
}

.form_namebox {
    display: flex;
    gap: 2%;
}

.form_namebox div {
    width: 49%;
}

.form_alert {
    color: #ff4400;
    text-align: right;
    font-size: 0.9rem;
    margin-top: -24px;
    margin-right: 5px;
    height: 24px;
    font-style: italic;
}


@media screen and (min-width: 769px) {
    .form_namebox {
        display: flex;
        gap: 0 8px;
    }


}