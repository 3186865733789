.tarot_page {
    position: relative;
}

.img_table {
    width: 100%;
    opacity: 0.8;
    border-radius: 15px;
}

.tarot_page p {
    color: #3c0040;
    font-size: 1.5rem;
    margin-bottom: 120px;
    margin-left: 20px;
}

.tarot_page {

}

.tarot_page {

}

.tarot_page {

}

.fade-inD {
    opacity: 0;
    transform: translate(0, -60%);
    transition: 0.8s;
}

.fade-inslow {
    position: absolute;
    height: auto;
    width: 25%;
    right: 9%;
    rotate: 13deg;
    opacity: 0;
    transform: translate(0, 30%);
    transition: 0.8s;
}

.fade-in_delayed {
    transition-delay: 0.4s;
}

.isfade_in {
    opacity: 1;
    transform: translate(0) rotate(0deg);
}

.isfade_in_bg {
    opacity: 0.3;
    transform: translate(0) rotate(0deg);
}


.bgtarot04 {
    top: 20%;
    rotate: -13deg;
}

.bgtarot01 {
    top: 40%;
    rotate: 13deg;

}

.bgtarot02 {
    top: 55%;
    rotate: -13deg;
}

.bgtarot03 {
    top: 70%;
    rotate: 13deg;
}


.bgtarot05 {
    top: 85%;
    rotate: -13deg;
}

.tarot_info {
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 32px !important;
}

.palettered a {
    background-image: url("../asset/Image2.png");
}

@media screen and (min-width: 769px) {
    .fade-inslow {
        max-width: 100px;
    }

}


