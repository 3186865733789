@import url('https://fonts.googleapis.com/css2?family=Abel&family=Allura&family=Cinzel&family=Dancing+Script&family=Eagle+Lake&family=Italianno&family=Playfair&family=Poltawski+Nowy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bodoni+Moda:opsz@6..96&family=Merienda&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Narrow&family=Quicksand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Zen+Antique&display=swap');

@font-face {
    font-family: "Monotype_Corsiva";
    src: url("Monotype Corsiva.ttf") format("truetype")
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    margin: 0 0;
    padding: 0 0;
    color: #1a1a1a;
    font-family: 'Playfair', serif;
}

.style_jp {
    font-family: 'Zen Antique', serif !important;
}

.txt_white {
    color: rgb(244, 245, 247) !important;
}

p, a, h1, h2, h3, h4, h5, h6, li, ul, ol,
div, input, textarea,
input::placeholder, textarea::placeholder {

}

.style_jp input::placeholder, .style_jp textarea::placeholder {
    font-family: 'Zen Antique', serif !important;
}

a {
    color: inherit;
    text-decoration: none;
}

Button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
}

.Playfair {
    font-family: 'Playfair', serif;
}

.Italianno {
    font-family: 'Italianno', serif;
}

.Corsiva {
    font-family: 'Monotype_Corsiva', cursive;
}

.Bodoni {
    font-family: 'Bodoni Moda', serif;
}

.Merienda {
    font-family: 'Merienda', cursive;
}

.oswald {
    font-family: 'Oswald', sans-serif;

}

.Quicksand {
    font-family: 'Quicksand', sans-serif;
}

.PTSansN {
    font-family: 'PT Sans Narrow', sans-serif;
}

.Cinzel {
    font-family: "Cinzel", serif;
}


.text {
    mix-blend-mode: difference;
}


.flex_centering {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.absolute_centering {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.width100 {
    width: 100%;
}

.width50 {
    width: 50%;
}


@media screen and (max-width: 768px) {
    .pc {
        display: none;
    }

    .sp {
        display: block;
    }
}

@media screen and (min-width: 769px) {
    .pc {
        display: block;
    }

    .sp {
        display: none;
    }
}