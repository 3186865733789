@import "../index.css";

.header_container {
    position: relative;
    width: 100%;
    height: 650px;
    color: rgb(244, 245, 247);
    background-color: rgb(201, 201, 201);
    font-weight: normal;
    overflow: hidden;
    z-index: 2;
}

.header_container:after {
    content: "";
    background-color: rgba(244, 245, 247, 0.2);
    z-index: -1;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

.bgimage {
    z-index: -2;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: top center;
    transform: scale(1.1);
}

@keyframes fade-in-animation1 {

    0% {
        opacity: 1;
        transform: scale(1.05)
    }

    100% {
        opacity: 1;
        transform: scale(1.3)
    }
}

@keyframes fade-in-animation2 {
    0% {
        opacity: 1;
        transform: scale(1.05)
    }

    100% {
        opacity: 1;
        transform: scale(1.3)
    }
}

.header_container h1 {
    position: absolute;
    top: 300px;
    margin-left: 10%;
    font-size: 2.8rem;
    text-shadow: 1px 1px 2px #231001;
    color: rgb(244, 245, 247);
    font-weight: normal;
}

.header_container h1 .h1_s {
    line-height: normal;
    font-size: 1.2rem;
    color: rgb(244, 245, 247);
}


.header_btn {
    position: absolute;
    bottom: 5%;
    width: 100%;
    text-align: center;
}

.header_btn a {
    white-space: nowrap;
    display: inline-block;
    padding: 10px 50px;
    color: rgb(244, 245, 247);
    border: #F4F5F7 1px solid;
    background: radial-gradient(circle, rgba(190, 190, 190, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%);
    border-radius: 25px;
    font-size: 1.5rem;
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
    box-shadow: 0 5px 20px rgba(100, 100, 100, 0.5); /* 薄い影 */
}


.header_container h2 {
    position: absolute;
    top: 490px;
    width: 100%;
    text-align: center;
    font-size: 1.1rem;
    text-shadow: 1px 1px 2px #231001;
    color: rgb(244, 245, 247);
    font-weight: normal;
    font-style: italic;
}

.nouveautes {
    background-color: rgba(255, 255, 255, 0.5);
    height: auto;
}

.nouveautes h2 {
    margin: 2rem 0 0;
    padding: 1rem 0;
    color: #3d3d3d;
    font-size: 1.8rem;
    font-weight: bold;
}

.nouveautes_slider {
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: scroll;
}

.nouveautes_slider_item {
    margin: 12px 24px 12px 12px;
}

.n_imgbox {
    overflow: hidden;
    height: 160px;
    position: relative;
    filter: drop-shadow(12px 12px 4px rgba(31, 31, 31, 0.5));
    margin-bottom: 18px;
}

.n_imgbox img {
    z-index: 0;
    height: 101%;
    vertical-align: top;
    clip-path: polygon(0 0, 100% 0, 100% 99%, 0 99%);
}

.n_imgbox:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 10000;
}

.n_sold:after, .n_nonsidp:after {
    background-color: rgba(244, 245, 247, 0.4);
}

.n_sold:before {
    content: "SOLD";
    position: absolute;
    top: -50px;
    left: -100px;
    width: 200px;
    height: 100px;

    color: #F4F5F7;
    font-size: 1.2rem;
    line-height: 170px;
    text-align: center;

    background-color: #f10000;
    rotate: -45deg;
    z-index: 10001;
}


.n_nonsidp:before {
    content: "Non disponible";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    height: 60px;

    color: #F4F5F7;
    font-size: 1rem;
    line-height: 60px;
    text-align: center;

    background-color: rgba(65, 65, 65, 0.6);
    z-index: 10001;
}


.nouveautes_slider_item .nonsales,
.nouveautes_slider_item .undisp {
    font-size: 1rem;
    text-align: left;
}

.n_item_title {
    font-size: 1.2rem;
    white-space: nowrap;
}

.n_item_size {
    font-family: 'Poltawski Nowy', serif;
    font-size: 0.8rem;
    line-height: 18px;
}

.n_item_year {
    font-size: 1.2rem;
    line-height: 18px;
}

.n_item_price {
    font-size: 1.2rem;
}

.n_item_btn {
    font-size: 1.6rem;
    text-decoration: underline;
    line-height: 125%;
    min-width: 140px;
}

.undisp {
    color: #f10000;
    font-size: 2.2rem;
}





.scroll-snap {
    overflow: auto;
    scroll-snap-type: y mandatory;
    height: 100vh;
}

.scroll-snap_child {
    scroll-snap-align: start;
}

.sec_concept {

}

.sec_concept p {
    color: #a99167;
    position: relative;
    font-size: 1.3rem;
    text-align: right;
    line-height: 140%;
    padding: 50% 15% 140%;
    margin: 0 -12px;
    background: rgba(255, 255, 255, 0.8);
}

.sec_concept p:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url('../asset/gradie03.png');
    background-size: cover;
    background-position: center center;
    opacity: 0.8;
    z-index: -1;
}

.sec_concept h2 {
    margin: 120px 5% 120px;
    font-size: 2.6rem;
    font-family: "Cinzel", serif;
    text-align: center;
    background: rgb(255, 208, 120);
    background: radial-gradient(circle, rgba(255, 208, 120, 1) 0%, rgba(64, 55, 39, 1) 56%, rgba(28, 19, 0, 1) 100%);
    background-attachment: fixed;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.sec_tarot {
    padding: 40px 0;
    position: relative;
    text-align: center;
}

.sec_tarot h2 {
    position: relative;
    color: rgb(244, 245, 247);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    font-family: 'Bodoni Moda', serif;
    font-style: italic;
    font-size: 1.9rem;
    padding: 90px 0;
    margin: 0 0;
}




/*.sec_tarot h2:before {
    content: "";
    width: 160%;
    height: 160%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(20deg);
    background-image: url("../asset/tarotverso.jpg");
    background-size: cover;
    background-position: center;
    z-index: -2;
}
*/
.sec_tarot h2:after {
    content: "";
    width: 85%;
    height: 75%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #F4F5F7;
    z-index: -1;
}

.sec_tarot:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background-image: url("../asset/univerzzz.png");
    background-size: cover;
    opacity: 1;
    z-index: -1;
}

.sec_tarot p {
    font-size: 1.3rem;
    line-height: 150%;
    text-align: center;
    color: rgb(244, 245, 247);
    position: relative;
    padding: 40px 15%;
}

.sec_tarot img {
    width: 30%;
    box-shadow: #0a0a0a 0 0 10px;
    rotate: 10deg;
}


.btn_palette {
    text-align: center;
    margin: 50px 0;
}

.btn_palette a {
    transform: translateX(10%);
    min-width: 120px;
    display: inline-block;
    font-family: 'Oswald', sans-serif;
    padding: 30px 70px 40px 30px;
    color: rgb(244, 245, 247);
    font-size: 1.3rem;
    text-align: center;
    background-image: url("../asset/Image5.png");
    background-size: cover;
    background-position: center;
}

.btnbl a {
    background-image: url("../asset/Image2 copie.png");
}


.fade-in {
    opacity: 0;
    transform: translate(0, 15%);
    transition: 0.8s;
}

.fade-inR {
    opacity: 0;
    transform: translate(10%, 0);
    transition: 0.8s;
}

.fade-inD {
    opacity: 0;
    transform: translate(0, -60%);
    transition: 0.8s;
}

.fade-in_delayed {
    transition-delay: 0.4s;
}

.isfade_in {
    opacity: 1;
    transform: translate(0) rotate(0deg);
}


.style_jp .sec_concept h2 {
    font-size: 2rem;
}

.style_jp .header_container h1 .h1_s,
.style_jp .header_container h2,

.style_jp .header_container .header_btn a,
.style_jp .n_item_btn a b,
.style_jp .sec_concept p,
.style_jp .sec_tarot p,
.style_jp.sec_tarot h2,
.style_jp .page_content h2,
.style_jp .btn_palette a,
.style_jp .n_item_title,
.style_jp .nonsales ,
.style_jp .undisp  {
    font-family: "Zen Antique", serif;
}





@media screen and (min-width: 769px) {

    .header_container {
        height: 90vh;
    }

    .sec_concept p {
        padding: 50% 15% 140%;
        font-size: 1.7rem;
    }

    .sec_concept p:before {
        background-image: url('../asset/gradi044.png');
    }

    .sec_tarot img {
        display: inline;
        margin: 0 30px;
        width: 60px;
        box-shadow: #0a0a0a 0 0 10px;
        rotate: 10deg;
    }


}