.bio-page {

}

.bio-page h3 {
    font-family: 'Italianno', serif;
    font-size: 3rem;
    margin: 40px 0;
    color: #575757;
}

.bio-page p {
    font-size: 1.3rem;
    margin-bottom: 80px;
    margin-left: 12px;
    text-align: center;
}

.bio-page span {
    font-size: 2.6rem;
    margin-bottom: 20px;
}

.bio-page img {
    text-align: center;
    margin: 0 auto;
    filter: grayscale(1);
}

.bio-page {

}