.footer {
    position: relative;
    bottom: 0;
    background-color: #F4F5F7;
}

.footer_main {
    max-width: 900px;
    margin: 0 auto;
    padding: 24px 5%;
    display: flex;
}

.footer_main div {
    width: 40%;
}

.footer_main ul {
    width: 60%;
    list-style: none;
}

.footer_main ul li {
    line-height: 200%;
    font-size: 1.2rem;
}

.footer_logo {
    text-align: left;
}

.footer_logo p {
    font-size: 1.6rem;
}

.footer_bottom {
    max-width: 900px;
    margin: 0 auto;
    padding: 8px 5%;
    text-align: center;
    z-index: 997;
}

.footer_bottom p {
    font-size: 12px;
}

.style_jp li a {
    font-family: 'Zen Antique', serif;
    font-size: 1.1rem;
}

@media screen and (min-width: 769px) {

    .footer {
        padding: 0 25%;
    }
}